import * as cpf from '@fnando/cpf';

/**
 * Verifica se o CPF é válido
 * @param  {string} value
 * @returns boolean
 */
export function validCpf(value: string): boolean {
  const sanitize: string = formatCpf(value);
  return cpf.isValid(sanitize);
}

/**
 * Retorna o CPF formatado
 * @param  {string} value
 * @returns string
 */
export function formatCpf(value: string): string {
  return cpf.format(value);
}

/**
 * Retorna um CPF fake
 * @returns string
 */
export function fakeCpf(): string {
  return cpf.generate(true);
}
