import { registerLocaleData } from '@angular/common';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsDaterangepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { esLocale, ptBrLocale } from 'ngx-bootstrap/locale';
import { STORAGE_TYPE, StorageService } from '../storage';
import { LANG_EN_CONFIG } from './config/en';
import { LANG_ES_CONFIG } from './config/es';
import { LANG_PT_CONFIG } from './config/pt';
import { LANG_EN_DICTIONARY } from './dictionary/en';
import { LANG_ES_DICTIONARY } from './dictionary/es';
import { LANG_PT_DICTIONARY } from './dictionary/pt';
import { Config, Languages } from './translate.types';

defineLocale('es', esLocale);
defineLocale('pt-br', ptBrLocale);

@Injectable({ providedIn: 'root' })
export class TranslateService implements OnDestroy {
  private currentLanguage: Languages;
  private readonly config: Map<string, Config> = new Map<string, Config>();
  private readonly dictionary: Map<string, any> = new Map<string, any>();

  public onLanguageChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private readonly bsLocaleService: BsLocaleService,
    private readonly bsDatepickerConfig: BsDatepickerConfig,
    private readonly bsDaterangePickerConfig: BsDaterangepickerConfig,
    private readonly storageService: StorageService
  ) {
    this.loadConfig();
    this.loadDictionary();
    this.loadLanguage();
    this.setLocaleDate();
    this.saveLanguage();
    this.onLanguageChanged.emit(this.currentLanguage);
  }

  public ngOnDestroy(): void {
    this.onLanguageChanged.unsubscribe();
  }

  public useLanguage = (language: Languages): void => {
    this.currentLanguage = language;
    this.setLocaleDate();
    this.saveLanguage();
    this.onLanguageChanged.emit(language);
  };

  public translate = (key: string): string => {
    const dictionary: Map<string, any> = this.dictionary.get(this.currentLanguage);
    const value: string = dictionary.get(key) ?? key;
    return value;
  };

  public get getConfig(): Config {
    const config: Config = this.config.get(this.currentLanguage);
    return config;
  }

  private readonly setLocaleDate = (): void => {
    registerLocaleData(this.getConfig.localeDate);
    this.bsLocaleService.use(this.getConfig.localeDate);
    this.bsDatepickerConfig.dateInputFormat = this.getConfig.date.format;
    this.bsDatepickerConfig.showWeekNumbers = false;
    this.bsDatepickerConfig.containerClass = 'theme-dark-blue';
    this.bsDatepickerConfig.isAnimated = true;
    this.bsDaterangePickerConfig.dateInputFormat = this.getConfig.date.format;
    this.bsDaterangePickerConfig.showWeekNumbers = false;
    this.bsDaterangePickerConfig.containerClass = 'theme-dark-blue';
    this.bsDaterangePickerConfig.isAnimated = true;
  };

  private readonly loadConfig = (): void => {
    this.config.set('en', LANG_EN_CONFIG);
    this.config.set('es', LANG_ES_CONFIG);
    this.config.set('pt', LANG_PT_CONFIG);
  };

  private readonly loadDictionary = (): void => {
    this.dictionary.set('en', this.loadDictionaryTranslates(LANG_EN_DICTIONARY));
    this.dictionary.set('es', this.loadDictionaryTranslates(LANG_ES_DICTIONARY));
    this.dictionary.set('pt', this.loadDictionaryTranslates(LANG_PT_DICTIONARY));
  };

  private readonly loadDictionaryTranslates = (language: any): Map<string, any> => {
    const dictionary: Map<string, any> = new Map<string, any>();
    Object.entries(language).forEach(([key, value]) => {
      dictionary.set(key, value);
    });
    return dictionary;
  };

  private readonly loadLanguage = (): Languages => {
    const language: Languages = this.storageService.get(STORAGE_TYPE.LOCAL, 'language') ?? 'pt';
    this.currentLanguage = language;
    return language;
  };

  private readonly saveLanguage = (): void => {
    this.storageService.set(STORAGE_TYPE.LOCAL, 'language', this.currentLanguage);
  };
}
