import { Injectable } from '@angular/core';
import Swal, { SweetAlertCustomClass, SweetAlertIcon, SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { TranslateService } from '../translate';
import { AlertInterface } from './alert.interface';

@Injectable({ providedIn: 'root' })
export class AlertService implements AlertInterface {
  private readonly defaultOptions: SweetAlertOptions = {
    confirmButtonColor: '#1B8EB7',
    cancelButtonColor: '#F64846',
  };

  private readonly customClass: SweetAlertCustomClass = {
    confirmButton: 'btn btn-lg btn-primary',
    cancelButton: 'btn btn-lg btn-danger',
  };

  constructor(private readonly translateService: TranslateService) {}

  public async message(html: string): Promise<void> {
    const options: SweetAlertOptions = {
      html,
    };
    await Swal.fire({ customClass: this.customClass, ...this.defaultOptions, ...(options as any) });
  }

  public async success(text: string, title?: string): Promise<void> {
    const icon: SweetAlertIcon = 'success';
    const options: SweetAlertOptions = {
      icon,
      title: title ? title : this.translateService.translate('ALERTA_SUCESSO'),
      html: text,
    };
    await Swal.fire({ customClass: this.customClass, ...this.defaultOptions, ...(options as any) });
  }

  public async error(text: string, title?: string): Promise<void> {
    const icon: SweetAlertIcon = 'error';
    const options: SweetAlertOptions = {
      icon,
      title: title ? title : this.translateService.translate('ALERTA_ERRO'),
      html: text,
    };
    await Swal.fire({ customClass: this.customClass, ...this.defaultOptions, ...(options as any) });
  }

  public async warning(text: string, title?: string): Promise<void> {
    const icon: SweetAlertIcon = 'warning';
    const options: SweetAlertOptions = {
      icon,
      title: title ? title : this.translateService.translate('ALERTA_ATENCAO'),
      html: text,
    };
    await Swal.fire({ customClass: this.customClass, ...this.defaultOptions, ...(options as any) });
  }

  public async info(text: string, title?: string): Promise<void> {
    const icon: SweetAlertIcon = 'info';
    const options: SweetAlertOptions = {
      icon,
      title: title ? title : this.translateService.translate('ALERTA_INFORMACAO'),
      html: text,
    };
    await Swal.fire({ customClass: this.customClass, ...this.defaultOptions, ...(options as any) });
  }

  public async question(text: string, title?: string): Promise<SweetAlertResult<any>> {
    const icon: SweetAlertIcon = 'question';
    const options: SweetAlertOptions = {
      icon,
      title: title ? title : this.translateService.translate('ALERTA_QUESTAO'),
      html: text,
      showConfirmButton: true,
      confirmButtonText: this.translateService.translate('BOTAO_SIM'),
      showCancelButton: true,
      cancelButtonText: this.translateService.translate('BOTAO_NAO'),
      focusConfirm: false,
      focusCancel: true,
      showCloseButton: true,
    };
    return Swal.fire({ customClass: this.customClass, ...this.defaultOptions, ...(options as any) });
  }
}
