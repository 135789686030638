import { Config } from '../translate.types';

export const LANG_PT_CONFIG: Config = {
  locale: 'pt-BR',
  localeDate: 'pt-br',
  code: 'BRL',
  date: {
    format: 'DD/MM/YYYY',
    mask: '99/99/9999',
    timezone: {
      format: 'YYYY-MM-DD',
      value: 'T03:00:00.000Z',
    },
  },
  currency: {
    thousands: '.',
    decimal: ',',
    symbol: 'R$',
  },
  number: {
    thousands: ',',
    decimal: '.',
  },
};
