import * as cnpj from '@fnando/cnpj';

/**
 * Verifica se o CNPJ é válido
 * @param  {string} value
 * @returns boolean
 */
export function validCnpj(value: string): boolean {
  const sanitize: string = formatCnpj(value);
  return cnpj.isValid(sanitize);
}

/**
 * Retorna o CNPJ formatado
 * @param  {string} value
 * @returns string
 */
export function formatCnpj(value: string): string {
  return cnpj.format(value);
}

/**
 * Retorna um CNPJ fake
 * @returns string
 */
export function fakeCnpj(): string {
  return cnpj.generate(true);
}
