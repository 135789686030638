export const LANG_PT_DICTIONARY = {
  LANG_EN: 'Inglês',
  LANG_ES: 'Espanhol',
  LANG_PT: 'Português',

  ALERTA_SUCESSO: 'Sucesso',
  ALERTA_ERRO: 'Erro',
  ALERTA_ATENCAO: 'Atenção',
  ALERTA_INFORMACAO: 'Informação',
  ALERTA_QUESTAO: 'Confirma?',
  ALERTA_CAMPO: 'Verifique as informações',
  ALERTA_EXCLUIR: 'Confirma a Exclusão?',

  BOTAO_OK: 'OK',
  BOTAO_SIM: 'Sim',
  BOTAO_NAO: 'Não',
  BOTAO_GRAVAR: 'Gravar',
  BOTAO_CONFIRMAR: 'Confirmar',
  BOTAO_CANCELAR: 'Cancelar',
  BOTAO_SAIR: 'Sair',
  BOTAO_CADASTRAR: 'Cadastrar',
  BOTAO_ALTERAR: 'Alterar',
  BOTAO_EXCLUIR: 'Excluir',
  BOTAO_ENVIAR: 'Enviar',
  BOTAO_IMPRIMIR: 'Imprimir',
  BOTAO_CONFIGURAR: 'Configurar',
  BOTAO_COMPRAR: 'Comprar',
  BOTAO_VENDER: 'Vender',
  BOTAO_PROCESSAR: 'Processar',
  BOTAO_PROCESSAR_PENDENTES: 'Processar Pendentes',

  HEADER_ALERTAS: 'Alertas',
  HEADER_CONFIGURACOES: 'Configurações',
  HEADER_USUARIO: 'Usuário',
  HEADER_EMPRESA: 'Empresa',
  HEADER_SUPORTE: 'Suporte',
  HEADER_CONTATO: 'Contato',
  HEADER_AJUDA: 'Ajuda',
  HEADER_FEEDBACK: 'Feedback',
  HEADER_SAIR: 'Sair',
  HEADER_CONTA: 'Conta',
  HEADER_CONTAS: 'Contas',
  HEADER_CORRETORA: 'Corretora',
  HEADER_CORRETORAS: 'Corretoras',
  HEADER_CARTEIRA: 'Carteira',
  HEADER_CARTEIRAS: 'Carteiras',
  HEADER_LEAD: 'Lead',
  HEADER_LEADS: 'Leads',
  HEADER_ATIVO: 'Ativo',
  HEADER_ATIVOS: 'Ativos',
  HEADER_ESTRATEGIAS: 'Estratégias',
  HEADER_ESTRATEGIA: 'Estratégia',
  HEADER_COMPRAR: 'Comprar',
  HEADER_VENDER: 'Vender',
  HEADER_TRANSFERENCIA: 'Transferência',
  HEADER_OPERACAO: 'Operação',
  HEADER_OPERACOES: 'Operações',
  HEADER_OPERACOES_ABERTAS: 'Abertas',
  HEADER_OPERACOES_FECHADAS: 'Fechadas',
  HEADER_COMISSAO: 'Comissão',
  HEADER_COMISSOES: 'Comissões',
  HEADER_COMISSOES_CONCLUIDAS: 'Concluídas',
  HEADER_COMISSOES_PENDENTES: 'Pendentes',
  HEADER_PROFIT: 'Lucratividade',

  LABEL_CODIGO: 'Código',
  LABEL_DESCRICAO: 'Descrição',
  LABEL_NOME: 'Nome',
  LABEL_EMAIL: 'E-mail',
  LABEL_SENHA: 'Senha',
  LABEL_PLACA: 'Placa',
  LABEL_CNPJ: 'CNPJ',
  LABEL_CPF: 'CPF',
  LABEL_CONTADOR: 'Contador',
  LABEL_TELEFONE: 'Telefone',
  LABEL_CELULAR: 'Celular',
  LABEL_CONFIGURACAO: 'Configuração',
  LABEL_CONFIGURACAO_COMPRA: 'Configuração de Compra',
  LABEL_CONFIGURACAO_VENDA: 'Configuração de Venda',
  LABEL_COMPRA: 'Compra',
  LABEL_VENDA: 'Venda',
  LABEL_MEDIO: 'Preço Médio',
  LABEL_STATUS: 'Status',
  LABEL_EXCHANGE: 'Corretora',
  LABEL_SYMBOL: 'Ativo',
  LABEL_CAPITAL: 'Capital',
  LABEL_RSI: 'RSI',
  LABEL_PRECO: 'Preço',
  LABEL_PRECO_MEDIO: 'Preço Médio',
  LABEL_PERCENTUAL: 'Percentual',
  LABEL_SALDO: 'Saldo',
  LABEL_SALDOS: 'Saldos',
  LABEL_SISTEMA_STATUS: 'Status do Sistema',
  LABEL_SISTEMA_LOG: 'Log do Sistema',
  LABEL_CONTA: 'Conta',
  LABEL_CONTAS: 'Contas',
  LABEL_AMOUNT: 'Quantidade',
  LABEL_ATIVAR: 'Ativar',
  LABEL_PERIODO: 'Período',

  TABLE_ACTIVE: 'Ativa',
  TABLE_TRADE: 'Operações',
  TABLE_ARBITRAGE: 'Arbitragem',
  TABLE_BOOK: 'Book',
  TABLE_TICKER: 'Ticker',
  TABLE_EXCHANGE: 'Corretora',
  TABLE_SYMBOL: 'Ativo',
  TABLE_RSI: 'RSI',
  TABLE_PRICE: 'Preço',

  MENSAGEM_CADASTRAR_SUCESSO: 'Cadastrado com sucesso',
  MENSAGEM_CADASTRAR_ERRO: 'Erro no cadastro',
  MENSAGEM_ALTERAR_SUCESSO: 'Alterado com sucesso',
  MENSAGEM_ALTERAR_ERRO: 'Erro na alteração',
  MENSAGEM_EXCLUIR_SUCESSO: 'Excluido com sucesso',
  MENSAGEM_EXCLUIR_ERRO: 'Erro na exclusão',

  MENU_DASHBOARD: 'Dashboard',
  MENU_PROFIT: 'Lucratividade',
  MENU_OPERACOES: 'Operações',
  MENU_COMISSOES: 'Comissões',
  MENU_CADASTRO: 'Cadastro',
  MENU_CADASTROS: 'Cadastros',
  MENU_CONTA: 'Conta',
  MENU_CONTAS: 'Contas',
  MENU_LEAD: 'Lead',
  MENU_LEADS: 'Leads',
  MENU_CORRETORAS: 'Corretoras',
  MENU_CARTEIRAS: 'Carteiras',
  MENU_ATIVOS: 'Ativos',
  MENU_ADMIN: 'Admin',
  MENU_ESTRATEGIA: 'Estratégias',
  MENU_COMPRAR: 'Comprar',
  MENU_VENDER: 'Vender',
  MENU_PRECO: 'Médias',
  MENU_SALDO: 'Saldos',
  MENU_SISTEMA: 'Sistema',
  MENU_STATUS: 'Status',

  PAGINACAO_PRIMEIRO: 'Primeiro',
  PAGINACAO_ANTERIOR: 'Anterior',
  PAGINACAO_PROXIMO: 'Próximo',
  PAGINACAO_ULTIMO: 'Último',

  PLACEHOLDER_CODIGO: 'Digite o Código',
  PLACEHOLDER_DESCRICAO: 'Digite a Descrição',
  PLACEHOLDER_NOME: 'Digite o Nome',
  PLACEHOLDER_EMAIL: 'Digite o E-mail',
  PLACEHOLDER_SENHA: 'Digite a Senha',
  PLACEHOLDER_PLACA: 'AAA-0000',
  PLACEHOLDER_CEP: '00000-000',
  PLACEHOLDER_IE: '000.000.000.000.00',
  PLACEHOLDER_CNPJ: '00.000.000/0000-00',
  PLACEHOLDER_CPF: '000.000.000-00',
  PLACEHOLDER_CONTADOR: 'Selecione o Contador',
  PLACEHOLDER_CONTATO: 'Digite o Contato',
  PLACEHOLDER_TELEFONE: '(00) 0000-0000',
  PLACEHOLDER_CELULAR: '(00) 00000-0000',
  PLACEHOLDER_RAZAO: 'Digite a razão social',
  PLACEHOLDER_FANTASIA: 'Digite o nome fantasia',

  TOAST_SUCESSO: 'Sucesso',
  TOAST_ERRO: 'Erro',
  TOAST_ATENCAO: 'Atenção',
  TOAST_INFORMACAO: 'Informação',

  TOOLTIP_CADASTRAR: 'Cadastrar',
  TOOLTIP_ALTERAR: 'Alterar',
  TOOLTIP_EXCLUIR: 'Excluir',
  TOOLTIP_CONTA: 'Conta',
  TOOLTIP_AJUDA: 'Ajuda',
  TOOLTIP_SUPORTE: 'Suporte',
  TOOLTIP_CONTATO: 'Contato',
  TOOLTIP_ALERTA: 'Alertas',
  TOOLTIP_USUARIO: 'Usuário',
  TOOLTIP_SELECIONAR: 'Selecionar',
  TOOLTIP_OPCOES: 'Opções',
  TOOLTIP_CONFIGURACOES: 'Configurações',
  TOOLTIP_PERIODO: 'Selecionar Período',
  TOOLTIP_PESQUISA: 'Selecionar Campo',
  TOOLTIP_FILTRO: 'Filtrar Informações',

  USUARIO_LOGIN_BOTAO: 'ENTRAR',
  USUARIO_LOGIN_SUCESSO: 'Login efetuado com sucesso',
  USUARIO_LOGIN_ERRO: 'Não foi possível efetuar o login',
  USUARIO_LOGIN_SENHA: 'Esqueceu a Senha?',
  USUARIO_LOGIN_CRIAR: 'Não tem uma conta? Crie a sua agora!',
  USUARIO_LOGIN_ACESSO: 'Usuário não autorizado!',

  USUARIO_LOGIN_SOCIAL_ENTRAR: 'ou entre usando uma das contas abaixo',
  USUARIO_LOGIN_SOCIAL_CRIAR: 'ou crie usando uma das contas abaixo',

  USUARIO_LOGIN_DUVIDA: 'Dúvidas? Entre em contato com a gente!',
  USUARIO_LOGIN_DUVIDA_TELEFONE: '(11) 96392-5932',

  USUARIO_CRIAR_BOTAO: 'CRIE SUA CONTA',
  USUARIO_CRIAR_SUCESSO: 'Cliente criado com sucesso',
  USUARIO_CRIAR_ERRO: 'Não foi possível criar a conta',
  USUARIO_CRIAR_LOGIN: 'Já tem uma conta? Entre agora!',
  USUARIO_CRIAR_APROVACAO: 'Obrigado, aguarde o Administrador aprovar o seu cadastro',
  USUARIO_CRIAR_TERMOS: 'Li e concordo com os',
  USUARIO_CRIAR_TERMOS_LINK: ' Termos de Uso',

  USUARIO_SENHA_ALTERAR: 'ALTERAR',
  USUARIO_SENHA_CANCELAR: 'CANCELAR',
  USUARIO_SENHA_SUCESSO: 'Senha alterada com sucesso',
  USUARIO_SENHA_ERRO: 'Não foi possível alterar a senha, verifique e tente novamente',

  USUARIO_RESET_ENVIAR: 'ENVIAR',
  USUARIO_RESET_CANCELAR: 'CANCELAR',
  USUARIO_RESET_MENSAGEM: 'Enviaremos um link para redefinir a senha no e-mail abaixo',
  USUARIO_RESET_SUCESSO: 'E-mail enviado com sucesso! Verifique sua caixa de entrada',
  USUARIO_RESET_ERRO: 'Não foi possível enviar o e-mail, verifique os dados informados',

  VALIDACAO_OBRIGATORIO: 'Campo obrigatório',
  VALIDACAO_TAMANHO: 'Tamanho do Campo inválido',
  VALIDACAO_EMAIL: 'E-mail inválido',
  VALIDACAO_EMAIL_EXISTE: 'E-mail já cadastrado',
  VALIDACAO_SENHA: 'Senha muito curta',
  VALIDACAO_SENHA_CURTA: 'Senha muito curta',
  VALIDACAO_SENHA_DIFERENTES: 'Senhas não conferem',
  VALIDACAO_CEP: 'CEP inválido',
  VALIDACAO_CNPJ: 'CNPJ inválido',
  VALIDACAO_CNPJ_EXISTE: 'CNPJ já cadastrado',
  VALIDACAO_LIMITE: 'Valor fora do limite',

  WHATSAPP_TOOLTIP: 'Entre em contato com a gente! (11) 9 6392-5932',
  WHATSAPP_MENSAGEM: 'Olá, tudo bem? Gostaria de fazer um orçamento',
};
