import { environment } from '@env/environment';

export class ApiConfig {
  public static get url(): string {
    return environment.api.host;
  }

  public static get prefix(): string {
    return environment.api.prefix;
  }
}
