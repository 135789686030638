import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from '@app/auth/token.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { lastValueFrom, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ApiConfig } from './api.config';
import { METHOD } from './api.enum';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly deviceService: DeviceDetectorService,
    private readonly tokenService: TokenService
  ) {}

  public async get<T = any>({ path, query, params }: { path: string; query?: any; params?: any }): Promise<T> {
    return this.request<T>(METHOD.GET, path, undefined, query, params);
  }

  public async post<T = any>({ path, data, query, params }: { path: string; data?: any; query?: any; params?: any }): Promise<T> {
    return this.request<T>(METHOD.POST, path, data, query, params);
  }

  public async put<T = any>({ path, data, query, params }: { path: string; data?: any; query?: any; params?: any }): Promise<T> {
    return this.request<T>(METHOD.PUT, path, data, query, params);
  }

  public async patch<T = any>({ path, data, query, params }: { path: string; data?: any; query?: any; params?: any }): Promise<T> {
    return this.request<T>(METHOD.PATCH, path, data, query, params);
  }

  public async delete<T = any>({ path }: { path: string }): Promise<T> {
    return this.request<T>(METHOD.DELETE, path);
  }

  private async request<T = any>(method: METHOD, path: string, data?: any, query?: any, params?: any): Promise<T> {
    const url: any = this.makeUrl(path, query);
    const requestHeaders: HttpHeaders = this.makeHeaders(url);
    const requestParams: HttpParams = this.makeParams(params);
    const request: any = new HttpRequest<any>(method, url, data, {
      headers: requestHeaders,
      params: requestParams,
    });
    const request$ = this.httpClient.request(request).pipe(
      catchError((error: HttpErrorResponse) => this.handleError(error)),
      switchMap((response: any) => this.handleResponse(response))
    );
    return lastValueFrom<T>(request$);
  }

  protected handleError(error: HttpErrorResponse): any {
    const body: any = {
      ...error,
      statusText: error.message,
      message: error.status === 0 ? 'Serviço indisponível' : error.error.message,
    };
    return throwError(() => body);
  }

  protected handleResponse(response: any): Observable<any> {
    return of(response.body);
  }

  private readonly makeUrl = (path: string, query: any): string => {
    const p: string = path.startsWith('/') ? path : `/${path}`;
    let url: string = path.startsWith('http') ? path : `${ApiConfig.url}${ApiConfig.prefix}${p}`;
    if (query) {
      let q = '';
      Object.keys(query).forEach((key: string) => {
        q += `${q ? '&' : ''}${key}=${query[key]}`;
      });
      url = `${url}?${q}`;
    }
    return url;
  };

  private readonly makeHeaders = (url: string): HttpHeaders => {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Content-Type', 'application/json');
    httpHeaders = httpHeaders.append('Accept', 'application/json');
    httpHeaders = httpHeaders.append('DeviceInfo', JSON.stringify(this.deviceService.getDeviceInfo()));
    if (url.startsWith(ApiConfig.url)) {
      const accessToken: string = this.tokenService.token;
      if (accessToken) {
        httpHeaders = httpHeaders.append('Authorization', `Bearer ${accessToken}`);
      }
    }
    return httpHeaders;
  };

  private readonly makeParams = (params: any): HttpParams => {
    const httpParams: HttpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach((key: any) => {
        let value: any = params[key];
        value = typeof value === 'object' ? JSON.stringify(value) : value;
        params.append(key, value);
      });
    }
    return httpParams;
  };
}
