import { Injectable, Injector } from '@angular/core';
import { STORAGE_TYPE } from './storage.enum';
import { StorageFactory } from './storage.factory';

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor(private readonly injector: Injector) {}

  public set(storageType: STORAGE_TYPE, key: string, value: any): void {
    const storageService: any = this.injectService(storageType);
    storageService.set(key, value);
  }

  public get(storageType: STORAGE_TYPE, key: string): any {
    const storageService: any = this.injectService(storageType);
    return storageService.get(key);
  }

  public remove(storageType: STORAGE_TYPE, key: string): void {
    const storageService: any = this.injectService(storageType);
    storageService.remove(key);
  }

  private injectService(storageType: STORAGE_TYPE): any {
    const injectable: any = StorageFactory.new(storageType);
    return this.injector.get<Injector>(injectable);
  }
}
