import { Injectable, Injector } from '@angular/core';
import { DeviceService } from '../device';
import { DesktopToastService } from './desktop/desktop.service';
import { MobileToastService } from './mobile/mobile.service';
import { TOAST_TYPE } from './toast.enum';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(
    protected readonly base: Injector,
    private readonly deviceService: DeviceService,
    private readonly mobileToastService: MobileToastService,
    private readonly desktopToastService: DesktopToastService
  ) {}

  public success({ message, title = '', fixed = false }: { message: any; title?: string; fixed?: boolean }): void {
    this.message({ type: TOAST_TYPE.SUCCESS, message, title, fixed });
  }

  public warning({ message, title = '', fixed = false }: { message: any; title?: string; fixed?: boolean }): void {
    this.message({ type: TOAST_TYPE.WARN, message, title, fixed });
  }

  public error({ message, title = '', fixed = false }: { message: any; title?: string; fixed?: boolean }): void {
    this.message({ type: TOAST_TYPE.ERROR, message, title, fixed });
  }

  public info({ message, title = '', fixed = false }: { message: any; title?: string; fixed?: boolean }): void {
    this.message({ type: TOAST_TYPE.INFO, message, title, fixed });
  }

  private message({ type, message, title = '', fixed = false }: { type: TOAST_TYPE; message: any; title?: string; fixed?: boolean }): void {
    if (this.deviceService.isMobile()) {
      this.mobileToastService.message({ type, message, title, fixed });
    } else {
      this.desktopToastService.message({ type, message, title, fixed });
    }
  }
}
