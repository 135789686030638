import { Config } from '../translate.types';

export const LANG_ES_CONFIG: Config = {
  locale: 'es-ES',
  localeDate: 'es',
  code: 'EUR',
  date: {
    format: 'DD/MM/YYYY',
    mask: '99/99/9999',
    timezone: {
      format: 'YYYY-MM-DD',
      value: 'T03:00:00.000Z',
    },
  },
  currency: {
    thousands: '.',
    decimal: ',',
    symbol: 'EUR€',
  },
  number: {
    thousands: '.',
    decimal: ',',
  },
};
