import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { JwModalComponent } from './jw-modal.component';

@NgModule({
  imports: [CommonModule],
  declarations: [JwModalComponent],
  exports: [JwModalComponent],
})
export class JwModalModule {}
