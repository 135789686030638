import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { TranslateService } from '../../translate';
import { TOAST_TYPE } from '../toast.enum';

export const CONFIG: Partial<IndividualConfig> = {
  positionClass: 'toast-bottom-full-width',
  disableTimeOut: false,
  timeOut: 2000,
  closeButton: true,
  extendedTimeOut: 1000,
  progressBar: true,
  progressAnimation: 'decreasing',
  enableHtml: true,
  toastClass: 'ngx-toastr',
  titleClass: 'toast-title',
  messageClass: 'toast-message',
  tapToDismiss: true,
  newestOnTop: true,
};

@Injectable({ providedIn: 'root' })
export class MobileToastService {
  constructor(private readonly toastrService: ToastrService, private readonly translateService: TranslateService) {}

  public message({ type, message, title, fixed }: { type: TOAST_TYPE; message: any; title: string; fixed: boolean }): void {
    switch (type) {
      case TOAST_TYPE.SUCCESS:
        title = title ?? this.translateService.translate('TOAST_SUCESSO');
        setTimeout(() => {
          this.toastrService.success(message, title, { ...CONFIG, disableTimeOut: fixed });
        }, 0);
        break;
      case TOAST_TYPE.WARN:
        title = title ?? this.translateService.translate('TOAST_ATENCAO');
        setTimeout(() => {
          this.toastrService.warning(message, title, { ...CONFIG, disableTimeOut: fixed });
        }, 0);
        break;
      case TOAST_TYPE.ERROR:
        title = title ?? this.translateService.translate('TOAST_ERRO');
        setTimeout(() => {
          this.toastrService.error(message, title, { ...CONFIG, disableTimeOut: fixed });
        }, 0);
        break;
      case TOAST_TYPE.INFO:
        title = title ?? this.translateService.translate('TOAST_INFORMACAO');
        setTimeout(() => {
          this.toastrService.info(message, title, { ...CONFIG, disableTimeOut: fixed });
        }, 0);
        break;
      default:
        title = title ?? this.translateService.translate('TOAST_SUCESSO');
        setTimeout(() => {
          this.toastrService.success(message, title, { ...CONFIG, disableTimeOut: fixed });
        }, 0);
    }
  }
}
