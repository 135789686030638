import { Injectable } from '@angular/core';
import { STORAGE_TYPE, StorageService } from '@app/services/storage';
import { JwtHelperService } from '@auth0/angular-jwt';

const helper = new JwtHelperService();

@Injectable({ providedIn: 'root' })
export class TokenService {
  private readonly JWT_TOKEN: string = 'token';
  private jwtToken: string = '';
  private jwtTokenDecoded: { [key: string]: string };

  constructor(private readonly storageService: StorageService) {}

  public get id(): any {
    this.getToken();
    if (this.jwtTokenDecoded) {
      const { id } = this.jwtTokenDecoded;
      return id;
    }
    return undefined;
  }

  public get token(): string {
    this.getToken();
    return this.jwtToken;
  }

  public setToken(token: string): void {
    this.storageService.set(STORAGE_TYPE.LOCAL, this.JWT_TOKEN, token);
  }

  private getToken(): void {
    this.jwtToken = this.storageService.get(STORAGE_TYPE.LOCAL, this.JWT_TOKEN);
    this.decodeToken();
  }

  private decodeToken(): void {
    this.jwtTokenDecoded = this.jwtToken ? this.jwtDecode(this.jwtToken) : undefined;
  }

  public clearToken(): void {
    this.storageService.remove(STORAGE_TYPE.LOCAL, this.JWT_TOKEN);
  }

  public isExpired(): boolean {
    this.getToken();
    const isExpired = helper.isTokenExpired(this.jwtToken);
    return isExpired;
  }

  private jwtDecode(value: string): any {
    const token: any = helper.decodeToken(value);
    return token;
  }
}
