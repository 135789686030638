import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SpinService {
  private readonly show: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public getService(): Observable<boolean> {
    return this.show.asObservable();
  }

  public start(): void {
    this.show.next(true);
  }

  public stop(): void {
    this.show.next(false);
  }
}
